







































import { Component, PropSync, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Kanban_List extends Vue {
  @PropSync("view") icon!: any;
}
